<template>
  <div id="downloads_table_dash" class="vs-con-loading__container" >
    <vs-table
      :data="downloads"
      :noDataText="$t('DOWNLOAD.NO_DATA')"
      stripe
      max-items="5" 
      pagination
      hoverFlat>

      <template slot="header">
        <h3>
          {{$t('DOWNLOAD.TABLE.HEADER')}}
        </h3>
      </template>
      <template slot="thead">                               
        <vs-th sort-key="name">
          {{$t('DOWNLOAD.TABLE.COL1')}}
        </vs-th>
        <vs-th sort-key="filesize">
          {{$t('DOWNLOAD.TABLE.COL4')}}
        </vs-th>                                                                                                                                         
        <vs-th></vs-th>                                                                                                                                             
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >                   
          <vs-td :data="data[indextr].name">
            {{data[indextr].name}}
          </vs-td>                                            
          <vs-td :data="data[indextr].filesize">
            {{data[indextr].filesize.toFixed(2) + ' ' +data[indextr].filesize_ext}}
          </vs-td>                                                                                                                
          <vs-td>
            <vs-button size="small" icon="get_app" @click="DownloadAttachment(data[indextr].id)"></vs-button>
          </vs-td>                                                                                                                                                                                         
        </vs-tr>
      </template>
    </vs-table>
  </div> 
</template>
<script>

import downloadMethods from '../../../helper/staticDownloadFuncHelper';

export default {
  name: "DownloadsDash",
  props:[],
   data: function (){
     return {
      downloads:[]
     }
  },
  mounted:function(){
      this.LoadData();
  },
  computed:{
    getTableData() {
      var data = [];
      if(this.$store.state.download.downloads.data != null)
      {
        data = this.$store.state.download.downloads.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.download.downloads.status) != 'undefined')
        loading = this.$store.state.download.downloads.status.loading;
      return loading;
    }
  },
  methods:{
      ...downloadMethods,
      LoadData: function ()
      {
          this.$store.dispatch('download/getDownloads', { root: true },{ root: true }); 
      },
      DownloadAttachment(attId)
      {
        this.$store.dispatch('download/downloadFile', attId)
          .then(response => {
              this.DownloadWithFileSaver(response);
            }
          );
      },
  },
  watch:{
      getTableData(value) {
          this.downloads = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#downloads_table_dash'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#downloads_table_dash'});
        }
      },
  }
};
</script>
